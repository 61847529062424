import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BREAKPOINTS, GLOBAL, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT } from '../../constants';

const H2Styles = css`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY};
  font-size: ${FONT_SIZE.XX_LARGE};
  line-height: ${LINE_HEIGHT.HEADING.XX_LARGE};
  font-weight: ${FONT_WEIGHT.LIGHT};
  letter-spacing: 1px;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.XXX_LARGE};
    line-height: ${LINE_HEIGHT.HEADING.XXX_LARGE};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.XXXX_LARGE};
    line-height: ${LINE_HEIGHT.HEADING.XXXX_LARGE};
  }
`;

const H2 = styled.h2`
  ${H2Styles}
`;

export { H2, H2Styles };
