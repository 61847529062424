import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BREAKPOINTS, GLOBAL, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT } from '../../constants';

const SectionHeadingStyles = css`
  font-family: ${GLOBAL.BASE_FONT_FAMILY};
  font-size: ${FONT_SIZE.MEDIUM};
  line-height: ${LINE_HEIGHT.BODY.MEDIUM};
  font-weight: ${FONT_WEIGHT.REGULAR};
  letter-spacing: normal;
  margin: 0;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.LARGE};
    line-height: ${LINE_HEIGHT.BODY.LARGE};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.X_LARGE};
    line-height: ${LINE_HEIGHT.BODY.X_LARGE};
  }
`;

const SectionHeading = styled.p`
  ${SectionHeadingStyles}
`;

export { SectionHeading, SectionHeadingStyles };
