import { ExtraSmall, ExtraSmallStyles } from './extra-small';
import { H1, H1Styles } from './h1';
import { H2, H2Styles } from './h2';
import { H3, H3Styles } from './h3';
import { H4, H4Styles } from './h4';
import { H5, H5Styles } from './h5';
import { H6, H6Styles } from './h6';
import { H7, H7Styles } from './h7';
import { A, AStyles } from './a';
import { P, PStyles } from './p';
import { SectionHeading, SectionHeadingStyles } from './section-heading';
import { Small, SmallStyles } from './small';

export {
  ExtraSmall,
  ExtraSmallStyles,
  H1,
  H1Styles,
  H2,
  H2Styles,
  H3,
  H3Styles,
  H4,
  H4Styles,
  H5,
  H5Styles,
  H6,
  H6Styles,
  H7,
  H7Styles,
  A,
  AStyles,
  P,
  PStyles,
  SectionHeading,
  SectionHeadingStyles,
  Small,
  SmallStyles,
};
