import _ from 'lodash';
import { css } from '@emotion/react';

import BREAKPOINTS from './breakpoints';

const MEDIA_QUERIES = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  // We don't need the array indexes from breakpoints as variables
  // we can check for numbers to remove them
  const labelsAsNumber = parseFloat(label);
  if (_.isNaN(labelsAsNumber)) {
    acc[label] = (...args) => css`
      @media (min-width: ${BREAKPOINTS[label]}) {
        ${css(...args)};
      }
    `;

    acc[`${label}Down`] = (...args) => css`
      @media (max-width: calc(${BREAKPOINTS[label]} - 1px)) {
        ${css(...args)};
      }
    `;
  }
  return acc;
}, {});

export default MEDIA_QUERIES;
