const URLS = {
  COMPARE: '/compare',
  CONTACT_US: '/contact-us',
  HOME: '/',
  PRIVACY_POLICY: '/privacy-policy',
  RATINGS: '/ratings',
  RESULTS: '/results',
  SAFETY_FEATURES_LISTING: '/safety-features',
  TERMS_OF_USE: '/terms',
  SITEMAP: '/sitemap',

  FACEBOOK: 'https://www.facebook.com/TransportAccidentCommission',
  INSTAGRAM: 'https://www.instagram.com/tacvictoria/',
  TWITTER: 'https://twitter.com/tacvictoria',

  CHILD_CAR_SEATS: 'https://www.childcarseats.com.au',
};

export default URLS;
