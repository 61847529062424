import { createTheme } from '@mui/material/styles';
import { COLORS, FONT_SIZE, BREAKPOINTS } from '.';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.BRAND_BLUE_EIGHT,
    },
    secondary: {
      main: COLORS.GREY_SEVEN,
      dark: COLORS.GREY_ELEVEN,
    },
    error: {
      main: COLORS.DANGER_RED_EIGHT,
    },
  },
  breakpoints: {
    values: {
      xs: BREAKPOINTS.XS,
      sm: BREAKPOINTS.SM,
      md: BREAKPOINTS.MD,
      lg: BREAKPOINTS.LG,
      xl: BREAKPOINTS.XL,
    },
  },
  spacing: 4,
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 16,
    body1: {
      fontSize: FONT_SIZE.MEDIUM,
    },
    button: {
      fontSize: FONT_SIZE.MEDIUM,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          [`@media (min-width: ${BREAKPOINTS.XS}px)`]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
          [`@media (min-width: ${BREAKPOINTS.SM}px)`]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
          [`@media (min-width: ${BREAKPOINTS.MD}px)`]: {
            paddingLeft: 36,
            paddingRight: 36,
          },
          [`@media (min-width: ${BREAKPOINTS.LG}px)`]: {
            paddingLeft: 48,
            paddingRight: 48,
          },
          [`@media (min-width: ${BREAKPOINTS.XL}px)`]: {
            paddingLeft: 48,
            paddingRight: 48,
          },
        },
      },
    },
  },
});

export default muiTheme;
