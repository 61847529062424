import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BREAKPOINTS, GLOBAL, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT } from '../../constants';

const H6Styles = css`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY};
  font-size: ${FONT_SIZE.MEDIUM_SMALL};
  letter-spacing: 0;
  line-height: ${LINE_HEIGHT.HEADING.MEDIUM_SMALL};
  font-weight: ${FONT_WEIGHT.LIGHT};
  margin: 0;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.MEDIUM};
    line-height: ${LINE_HEIGHT.HEADING.MEDIUM};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.LARGE};
    line-height: ${LINE_HEIGHT.HEADING.LARGE};
  }
`;

const H6 = styled.h6`
  ${H6Styles}
`;

export { H6, H6Styles };
