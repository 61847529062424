import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BREAKPOINTS, GLOBAL, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT } from '../../constants';

const H1Styles = css`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY};
  font-size: ${FONT_SIZE.XXX_LARGE};
  line-height: ${LINE_HEIGHT.HEADING.XXX_LARGE};
  font-weight: ${FONT_WEIGHT.LIGHT};
  letter-spacing: 1px;
  margin: 0;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.XXXX_LARGE};
    line-height: ${LINE_HEIGHT.HEADING.XXXX_LARGE};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.XXXXX_LARGE};
    line-height: ${LINE_HEIGHT.HEADING.XXXXX_LARGE};
  }
`;

const H1 = styled.h1`
  ${H1Styles}
`;

export { H1, H1Styles };
